import request from '@/utils/request'
import SERVER from './server'
import { Toast } from 'vant'
import { thenResolve } from '@/utils/index'

export const taskStatistic = async(params) => {
  return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/task/allCount`, params).then(thenResolve)
}

export const taskListFetch = async(params) => {
  return await request.post(`${SERVER.LEAD_SERVICE}/api/v1/task/allPage`, params).then(thenResolve)
}