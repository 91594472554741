<template>
    <div class="calendar-shoutcut">
        <div class="time">
            <div :class="[dataText === 'today'?'active':'','today']" @click="onDateShortcut('today')">
                {{$t('今日')}}
            </div>
            <div :class="[dataText === 'week'?'active':'','week']" @click="onDateShortcut('week')">
                {{$t('本周')}}
            </div>
            <div :class="[dataText === 'month'?'active':'','month']" @click="onDateShortcut('month')">
                {{$t('本月')}}
            </div>
        </div>
        <img class="img" :src="calendarIcon" @click.stop="showCalendar = !showCalendar"/>
        <CommonDatePicker
          v-if="showCalendar"
          v-bind="$attrs"
          :defaultDateTimes="defaultDateTimes"
          :dateType="'daterange'"
          :showTitle="false"
          @confirm="handleDateTimeConfirm">
        </CommonDatePicker>
    </div>
</template>
<script>
import calendarIcon from '@/images/calendar.svg'
import dayjs from 'dayjs'
import CommonDatePicker from '@/components/v2/system/CommonDatePicker/index.vue'
export default {
  components:{ CommonDatePicker },
  model:{
    prop: 'value',
    event: 'update:value'
  },
  props:{
    value:{ // 开始-结束
      type: Array,
      default: () => []
    },
    defaultType:{ // 快捷项，默认“今天”
      type: String,
      default: 'today'
    },
  },
  data(){
    return {
      showCalendar: false,
      calendarIcon,
      dataText: '',
      defaultDateTimes: []
    }
  },
  created(){
    this.onDateShortcut(this.defaultType)
  },
  methods: {
    onDateShortcut(type){
      this.dataText = type
      let start = ''
      let end = ''
      switch (type) {
        case 'today':
          start = dayjs().format('YYYY-MM-DD 00:00:00')
          end = dayjs().format('YYYY-MM-DD 23:59:59')
          break
        case 'week':
          start = this.$moment().isoWeekday(1).format('YYYY-MM-DD 00:00:00') // 本周一
          end = this.$moment().isoWeekday(7).format('YYYY-MM-DD 23:59:59') // 本周日
          break
        case 'month':
          start = dayjs().startOf('month').format('YYYY-MM-DD 00:00:00')
          end = dayjs().endOf('month').format('YYYY-MM-DD 23:59:59')
          break
        default:
          
          break
      }
      this.defaultDateTimes = [start, end]
      this.$emit('update:value',[start,end])
      this.$emit('change',[start, end])
    },
    handleDateTimeConfirm(timeInfo){
      this.dataText = ''
      const { dateTimes:[start, end] } = timeInfo
      // 判断结束时间是否大于开始时间
      if (dayjs(end).isBefore(dayjs(start),'minute') || dayjs(end).isSame(dayjs(start),'minute')){
        return this.$toast(this.$t('请确认，结束时间要大于开始时间'))
      }
      this.showCalendar = false
      this.defaultDateTimes = [start, end]
      this.$emit('update:value',[start,end])
      this.$emit('change',[start, end])
    },
  }
}
</script>
<style lang="less" scoped>
.calendar-shoutcut{
  display: flex;
}
.time {
    width: max-content;
    font-size: 13px;
    display: flex;
    border: 1px solid #B9921A;
    > div {
        width: 52px;
        line-height: 22px;
        color: @yellow-text;
        text-align: center;
        &.active {
            background: @yellow-text;
            border: none;
            color: #fff;
            background-color: @yellow-text;
        }
        &:nth-of-type(2){
            border-left: 1px solid @yellow-text;
            border-right: 1px solid @yellow-text;
        }
    }
}
.img{
  width: 24px;
  height: 24px;
  margin-left: 14px;
  cursor: pointer;
}
</style>