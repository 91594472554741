import { i18n } from '@/main'
export const genderList = [
  { dictName: i18n.t('男'), dictCode: 1 },
  { dictName: i18n.t('女'), dictCode: 2 },
]
export const customerTypeList = [
  { dictName: i18n.t('个人购车'), dictCode: 1 },
  { dictName: i18n.t('公司购车'), dictCode: 2 },
]
// 0a60acc9744f大客户
export const bigCustomList = [
  { dictName: i18n.t('否'), dictCode: 0 },
  { dictName: i18n.t('是'), dictCode: 1 },
]
// 0a60acc9744f置换
export const isReplaceLIst = [
  { dictName: i18n.t('非置换'), dictCode: 0 },
  { dictName: i18n.t('置换'), dictCode: 1 },
]
// 0a60acc9744f估价
export const isEvaluateLIst = [
  { dictName: i18n.t('否'), dictCode: 0 },
  { dictName: i18n.t('是'), dictCode: 1 },
]
// 是否充电桩
export const isChargeList = [
  { dictName: i18n.t('否'), dictCode: 0 },
  { dictName: i18n.t('是'), dictCode: 1 },
]

// 是否有固定车位
export const isParkingSpaceList = [
  { dictName: i18n.t('无车位'), dictCode: 0 },
  { dictName: i18n.t('有'), dictCode: 1 },
]

// 是否有车辆指标
export const vehiclePlateList = [
  { dictName: i18n.t('电车指标'), dictCode: 0 },
  { dictName: i18n.t('油车指标'), dictCode: 1 },
  { dictName: i18n.t('暂无指标'), dictCode: 2 },
]

export const minDate = new Date()
export const maxDate = new Date(2099, 0, 31)
